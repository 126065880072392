<template>
  <div class="serviceAcc" :class="bg == 1 ? 'bg1' : bg == 2 ? 'bg2' : ''" v-scroll-reveal>
    <div class="flex title2">
      <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
      服务累计
      <img
        class="titleIcon1 rotate180"
        src="../assets/img/titleIcon1.png"
        alt=""
      />
    </div>
    <div class="flex mes2">
      <img src="../assets/img/titleIcon2.png" alt="" />
    </div>
    <div class="flex standings2">
      <div class="flex flexSB standingsCon2">
        <div class="list" v-for="(item, index) in standingsList" :key="index">
          <div class="data">
            <div class="flex flexB">
              <!-- :autoplay="false" -->
              <count-to
                :ref="'example' + index"
                :class="'example8' + index"
                :startVal="0"
                :endVal="item.data"
                :duration="4000"
              ></count-to>
              <div class="addIcon" v-if="item.type == 2">+</div>
            </div>
          </div>
          <div class="flex h-text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="smallTitle">
      <span>易策医管集团</span>已累计为超过<span>800+</span> 家医院提供优质服务
    </div>
    <div class="logoDiv2 flex flexS flexT">
      <div
        class="logoMes"
        v-for="(item, index) in hisList"
        :key="index"
        @click="toDetails(item)"
      >
        <img :src="item.hospitalLogo" />
        <div class="logoName flex">{{ item.hospitalName }}</div>
      </div>
    </div>
    <div class="flex mesMore" @click="toMoreMes">查看更多</div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import { getHospitalList } from "@/api/index";
export default {
  name: "serviceAccMod",
  components: { countTo },
  props: ["bg"], // 0：无背景，1：有左上角扩散点的背景图，2：只有底部波浪线的背景图
  data() {
    return {
      startVal: 0,
      endVal: 2022,
      duration: 4000,
      standingsList: [
        { data: 60, text: "三级综合医院", type: 2 },
        { data: 30, text: "专科医院", type: 2 },
        { data: 300, text: "二级综合医院", type: 2 },
        { data: 100, text: "妇幼保健院", type: 2 },
        { data: 130, text: "中医医院", type: 2 },
      ],
      hisList: [],
    };
  },
  created() {
    this.getList();
    console.log(this.bg);
  },
  methods: {
    getList() {
      getHospitalList().then((res) => {
        if (res.code == 200) {
          this.hisList = res.rows.slice(0, 10);
        }
      });
    },
    toMoreMes() {
      this.$router.push({ path: "/serve", query: { activeName: 1 } });
    },
  },
};
</script>

<style lang="scss">
.serviceAcc {
  width: 100%;
  height: 1185px;
  padding: 91px 0 0 0;
  background-repeat: no-repeat;
  .standings2 {
    .standingsCon2 {
      width: 1200px; /*no*/
      .list {
        width: 185px;
        // color: #fff;
        text-align: center;
        .data {
          display: inline-block;
          font-weight: 400;
          font-size: 46px;
          color: #2d43f2;
          //   margin-bottom: 14px;
          .addIcon {
            font-size: 30px;
            padding-bottom: 18px;
            color: #A5A5A5;
            margin-left: 5px;
          }
        }
        .h-text {
          font-weight: 400;
          font-size: 16px;
          color: #333333;
        }
      }
    }
  }
  .smallTitle {
    width: 1200px;
    text-align: center;
    padding-top: 70px;
    margin-left: 50%;
    transform: translateX(-50%);
    height: 150px;
    border-bottom: 1px solid #f1f5ff;
    span {
      color: #2d43f2;
    }
    span:nth-child(2) {
      font-size: 26px;
      margin: 0 8px;
    }
  }
  .logoDiv2 {
    width: 1330px;
    height: 579px;
    padding-top: 73px;
    margin-left: 50%;
    transform: translateX(-50%);
    flex-wrap: wrap;
    .logoMes {
      width: 266px;
      cursor: pointer;
      img {
        width: 138px;
        height: 138px;
        margin-left: 50%;
        transform: translateX(-50%);
      }
      .logoName {
        margin-top: 30px;
        margin-bottom: 60px;
        text-align: center;
        font-size: 14px;
      }
    }
    .logoMes:nth-child(5n) {
      margin-right: 0;
    }
  }
  .mesMore {
    width: 140px;
    height: 38px;
    margin-left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    font-size: 16px;
    color: #666666;
    cursor: pointer;
    margin-top: 20px;
  }
}
.bg1 {
  background-size: 100% 100%;
  background-image: url("../assets/img/serviceAccBg.png");
}
.bg2 {
  background-position: left bottom;
  background-image: url("../assets/img/core_advantage_bg2.png");
}
</style>