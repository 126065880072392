<template>
  <div class="expertItem2" v-scroll-reveal>
    <div class="flex title2">
      <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
      医院运营管理项目专家
      <img
        class="titleIcon1 rotate180"
        src="../assets/img/titleIcon1.png"
        alt=""
      />
    </div>
    <div class="flex mes2">
      <img src="../assets/img/titleIcon2.png" alt="" />
    </div>
    <div class="swiperDiv">
      <swiper
        :slides-per-view="3"
        :space-between="50"
        :options="swiperOption"
        ref="mySwiper"
      >
        <swiper-slide v-for="(item, index) in team" :key="index">
          <div class="swiperCon" style="height: 100%" :data-tbbsm="item">
            <img class="img" :src="item.img" />
            <div class="flex expertPresentation">
              {{ item.name }}
            </div>
            <div class="flex post">
              <span>{{ item.unit }}</span>
            </div>
            <!-- <div class="flexS step" style="color: #78787a">
                进一步了解
                <img
                  src="http://yice-prod.oss-cn-guangzhou.aliyuncs.com/img/home/next2.png"
                />
              </div> -->
          </div>
        </swiper-slide>
      </swiper>
      <div class="flex custom-button-prev"></div>
      <div class="flex custom-button-next"></div>
    </div>
    <div class="flex expertMore2" @click="toExpertItem">
      查看更多
    </div>
  </div>
</template>

<script>
import teamData from "../components/teamData.json";
export default {
  name: "ExpertItemMod",
  data() {
    let _this = this;
    return {
      team: teamData.team,
      swiperOption: {
        loop: true,
        speed: 1000,
        on: {
          slideChangeTransitionEnd: function () {
            _this.showTeamMes = _this.team[this.realIndex];
            // _this.swiperIndexChange(this.realIndex)
            _this.swiperIndex = this.realIndex;
          },

          click: function (e) {
            let obj = {};
            // console.log(e.target.src)
            if (e.target.src) {
              obj = _this.team.find((item) => {
                return item.img == e.target.src;
              });
            }
            console.log(obj);
            // let tbbsm = e.target.getAttribute("data-tbbsm"); // 获取自定义属性
            _this.toTeamDetails(obj); // 实现传参
          },
        },
        autoplay: true,
        slidesPerView: 5,
        // 自定义翻页按钮配置
        navigation: {
          prevEl: ".custom-button-prev", // 点击上一页
          nextEl: ".custom-button-next", // 点击下一张时
        },
        // 自定义翻页器配置
        pagination: {
          el: "nyll",
        },
      },
    };
  },
  methods: {
    // 跳转专家详情
    toTeamDetails(item) {
      this.$router.push({
        path: "/team",
        query: { userId: item.userId, activeName: item.activeName },
      });
    },
    // 跳转专家页面
    toExpertItem() {
      this.$router.push({
        path: "/team",
        query: { activeName: 1 },
      });
    },
  },
};
</script>

<style lang="scss">
.expertItem2 {
  width: 100%;
  height: 770px;
  // padding-bottom: 10px;
  background-image: url("../assets/img/moreWonderful_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 84px;
  .el-divider--horizontal {
    .el-divider__text.is-center {
      width: 67%;
    }
    .el-divider__text {
      background-color: #f5fbff;
    }
  }
  .swiperDiv {
    width: 1200px; 
    position: relative;
    margin: 0 auto;
    .swiper-container {
      height: 100%;
      .swiperCon {
        width: 230px;
        background-color: #fff;
        cursor: pointer;
        margin: 0 auto;
        .img {
          width: 230px;
          height: 339px;
          background-color: #fff;
          // border: 1px solid #dddcdb;
          // border-radius: 1px;
        }
        .expertPresentation {
          font-size: 20px;
          font-weight: bold;
          margin: 5px 0;
          .position {
            font-size: 15px;
            margin-left: 20px;
          }
        }
        .post {
          font-size: 16px;
          padding-top: 5px;
          padding-bottom: 18px;
        }
        .step {
          padding: 0 8px;
          font-size: 14px;
          img {
            margin-left: 5px;
          }
        }
      }
      .swiperCon:hover {
        background: #ecf7ff;
      }
    }
    .custom-button-prev {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: -80px;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      background-image: url("../assets/img/right2.png");
      background-size: 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
      cursor: pointer;
    }
    .custom-button-prev:hover {
      background-image: url("../assets/img/hoverRight2.png");
    }

    .custom-button-next {
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      right: -80px;
      width: 40px;
      height: 40px;
      background-image: url("../assets/img/right2.png");
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .custom-button-next:hover {
      background-image: url("../assets/img/hoverRight2.png");
    }
  }
  .expertMore2 {
    width: 140px;
    height: 38px;
    margin: 40px auto;
    background: white;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
  }
  .expertMore2:hover {
    background: #1E61FF;
    border: 1px solid #1E61FF;
    color: #fff;
  }
}
</style>