<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">医院战略发展规划</div>
      <div class="m_banner_i"></div>
      <div class="m_banner_text2">五定一做一分析，精准战略定位和发展方向</div>
    </div>
    <div class="development_m_box">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">项目框架</span>
        <span class="index_title_after"></span>
      </div>
      <img class="development_m_img" src="../assets/img/developmentImg1.png" alt="">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">实施效果</span>
        <span class="index_title_after"></span>
      </div>
      <div class="development_m_implement">
        <div class="development_m_implement_left">
          <div class="development_m_implement_name">发掘潜力</div>
          <div class="development_m_implement_info">发掘医院成长壮大的关键因素，确定战略地图，校正发展方向及行走步幅，实现医院总体目标。</div>
        </div>
        <img class="development_m_implement_img" src="../assets/img/development_m_img.png" alt="">
      </div>
      <div class="development_m_list">
        <div class="development_m_item">
          <div class="development_m_top">
            <img class="development_m_icom" src="../assets/img/development_m_icom4.png" alt="">
            <span class="development_m_name">谋篇布局</span>
          </div>
          <div class="development_m_title">为医院阶段性发展建立特色发展道路，夯实医疗管理制度。</div>
        </div>
        <div class="development_m_item">
          <div class="development_m_top">
            <img class="development_m_icom" src="../assets/img/development_m_icon3.png" alt="">
            <span class="development_m_name">五定</span>
          </div>
          <div class="development_m_title">定业务、定财务、定专科、定人员、定物资。</div>
        </div>
        <div class="development_m_item">
          <div class="development_m_top">
            <img class="development_m_icom" src="../assets/img/development_m_icon2.png" alt="">
            <span class="development_m_name">一做</span>
          </div>
          <div class="development_m_title">结合政策要求举措规划，助推医院高质量发展。</div>
        </div>
        <div class="development_m_item">
          <div class="development_m_top">
            <img class="development_m_icom" src="../assets/img/development_m_icon1.png" alt="">
            <span class="development_m_name">一分析</span>
          </div>
          <div class="development_m_title">在数据分析基础上确定“五定一做”， 重点分析业务、财务、人员、专科、物资等。</div>
        </div>
      </div>
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">项目成果</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">《医院发展环境分析报告及医院战略发展规划报告》</div>
      <div class="development_m_achievement">
        <img class="development_m_achievement_img" v-for="(item, index) in developmentImgList" :key="index" :src="item.img" />
      </div>
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">项目完成周期</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">项目周期为2个月，分为三大阶段十个步骤展开</div>
      <div class="development_m_cycle">
        <div class="development_m_cycle_item">
          <div class="development_m_cycle_name">调研与资料收集(1-2周)</div>
          <div class="development_m_cycle_introduce">
            <p>1.项目启动，观念先行;</p>
            <p>2.调研与资料收集;</p>
            <p>3.确定思路，结合国家、上级规划思路及要求，编制“十四五”规划纲要。</p>
          </div>
        </div>
        <img class="development_m_cycle_img" src="../assets/img/government_12.png" alt="" />
        <div class="development_m_cycle_item">
          <div class="development_m_cycle_name">报告撰写(3-6周)</div>
          <div class="development_m_cycle_introduce">
            <p>4.医院战略环境分析;</p>
            <p>5.确定战略目标，提出可实施的方案和保障措施;</p>
            <p>6.座谈和研讨，取得共识;</p>
            <p>7.完成并提交报告初稿。</p>
          </div>
        </div>
        <img class="development_m_cycle_img" src="../assets/img/government_17.png" alt="" />
        <div class="development_m_cycle_item">
          <div class="development_m_cycle_name">报告完善与提交(7-8周)</div>
          <div class="development_m_cycle_introduce">
            <p>8.座谈研讨，综合意见。</p>
            <p>9.完善项目报告。</p>
            <p>10.组织中高层十四五规划报告培训解读。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="serve_m_box">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">服务案例</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">易策医管集团已累计为超过<span style="color: #2D43F2;font-weight: 500">800+</span>家医院提供优质服务</div>
      <div class="serve_m_type">
        <div
          :class="['serve_m_type_item', isType == item.type ? 'serve_m_type_isItem' : '']"
          v-for="(item, index) in hisTypeList"
          :key="index" 
          @click="changeTab(item.type)"
        >
          {{ item.value }}
        </div>
      </div>
      <div class="serve_m_hospital">
        <div class="serve_m_hospital_item" v-for="(item, index) in hisList2" :key="index" @click="getDetails(item.hospitalId)">
          <img class="serve_m_hospital_img" :src="item.hospitalLogo" />
          <div class="serve_m_hospital_name">{{ item.hospitalName }}</div>
        </div>
      </div>
      <div class="index_more" @click="stretch">{{ hisShow ? '收起' : '查看更多' }}</div>
    </div>
  </div>
</template>

<script>
  import { getHospitalList } from "@/api/index";
  export default {
    props: ['developmentImgList'],
    data() {
      return {
        is_big: false,
        isType: null,
        hisShow: false,
        hisTypeList: [
          { type: null, value: "全部" },
          { type: "综合医院", value: "综合医院" },
          { type: "中医医院", value: "中医医院" },
          { type: "妇幼保健院", value: "妇幼保健院" },
          { type: "其他类型", value: "其他类型" },
        ],
        hisList2: []
      }
    },
    created() {
      this.changeTab(null)
    },
    methods: {
      changeTab(i) {
        this.isType = i;
        let data = {
          // hospitalProvince: this.HisTilter,
          hospitalType: i,
        };
        getHospitalList(data).then((res) => {
          if (res.code == 200) {
            this.hisList2 = res.rows.slice(0, 10);
            this.hisListCopy = JSON.parse(JSON.stringify(res.rows))
            this.hisShow = false
          }
        });
      },
      stretch(){
        this.hisShow = !this.hisShow
        if(this.hisShow == false){
          this.hisList2 = this.hisListCopy.slice(0, 10)
        }else{
          this.hisList2 = this.hisListCopy
        }
      },
      getDetails(hospitalId) {
        this.$router.push({
          path: "/serve",
          query: { activeName:2, hospitalId: hospitalId },
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg10.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .m_banner_text {
      font-size: 42px;
      color: #fff;
      text-align: center;
      padding-top: 132px;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 16px;
    }
    .m_banner_text2 {
      font-size: 26px;
      color: #fff;
      text-align: center;
    }
  }
  .index_title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #000;
    margin: 40px auto;
    .index_title_span {
      margin: 0 20px;
    }
    .index_title_after {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
    .index_title_before {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .index_subhead {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: #333;
    margin: 23px auto;
  }
  .development_m_box {
    padding: 37px;
    .development_m_img {
      width: 100%;
    }
    .development_m_implement {
      background-image: url("../assets/img/development_m_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      padding: 35px;
      .development_m_implement_left {
        width: calc(100% - 213px);
        .development_m_implement_name {
          font-size: 26px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 29px;
        }
        .development_m_implement_info {
          font-size: 20px;
          font-weight: 400;
          color: #4B4B4B;
          line-height: 35px;
          padding-right: 60px;
        }
      }
      .development_m_implement_img {
        width: 213px;
        height: 169px;
      }
    }
    .development_m_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 30px;
      .development_m_item {
        width: 48%;
        background: #FFFFFF;
        box-shadow: 0px 3px 16px 1px rgba(0,0,0,0.05);
        margin-bottom: 20px;
        padding: 17px 27px;
        .development_m_top {
          display: flex;
          align-items: center;
          .development_m_icom {
            width: 49px;
            height: 49px;
            margin-right: 27px;
          }
          .development_m_name {
            font-size: 22px;
            font-weight: bold;
            color: #333333;
          }
        }
        .development_m_title {
          font-size: 20px;
          font-weight: 400;
          color: #4B4B4B;
          line-height: 30px;
          margin-top: 15px;
        }
      }
    }
    .development_m_achievement {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .development_m_achievement_img {
        width: 260px;
        height: 368px;
        margin-bottom: 40px;
      }
    }
    .development_m_cycle {
      padding: 10px 30px;
      .development_m_cycle_item {
        background: #FFFFFF;
        box-shadow: 0px 3px 16px 1px rgba(167,167,167,0.2);
        border: 1px solid #E1E1E1;
        .development_m_cycle_name {
          font-size: 30px;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          height: 81px;
          line-height: 81px;
        }
        .development_m_cycle_introduce {
          font-size: 26px;
          font-weight: 400;
          color: #333333;
          line-height: 41px;
          padding: 32px 62px;
        }
        &:nth-child(1) {
          .development_m_cycle_name {
            background-color: #C43694;
          }
        }
        &:nth-child(3) {
          .development_m_cycle_name {
            background-color: #684AB7;
          }
        }
        &:nth-child(5) {
          .development_m_cycle_name {
            background-color: #2C4FDB;
          }
        }
      }
      .development_m_cycle_img {
        width: 17px;
        height: 40px;
        transform: rotate(90deg);
        margin: 0 auto;
        display: block;
      }
    }
  }
  .index_more {
    width: 175px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    border: 2px solid #999999;
    font-size: 22px;
    color: #666;
    text-align: center;
    margin: 20px auto 0;
  }
  .serve_m_box {
    background-color: #fff;
    padding: 50px 37px;
    .serve_m_type {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 60px;
      .serve_m_type_item {
        width: fit-content;
        padding: 21px 30px;
        background: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #BFBFBF;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
      }
      .serve_m_type_isItem {
        color: #fff;
        border: 1px solid #2D43F2;
        background: #2D43F2;
      }
    }
  }
  .serve_m_hospital {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 41px;
    .serve_m_hospital_item {
      width: 48%;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 3px 16px 1px rgba(0,14,52,0.06);
      border-radius: 4px;
      border: 2px solid #E6E6E6;
      margin-bottom: 28px;
      padding: 21px;
      .serve_m_hospital_img {
        width: 138px;
        height: 138px;
      }
      .serve_m_hospital_name {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        padding-top: 20px;
      }
    }
  }
</style>