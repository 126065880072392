var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"development_m_box"},[_vm._m(1),_c('img',{staticClass:"development_m_img",attrs:{"src":require("../assets/img/developmentImg1.png"),"alt":""}}),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"index_subhead"},[_vm._v("《医院发展环境分析报告及医院战略发展规划报告》")]),_c('div',{staticClass:"development_m_achievement"},_vm._l((_vm.developmentImgList),function(item,index){return _c('img',{key:index,staticClass:"development_m_achievement_img",attrs:{"src":item.img}})}),0),_vm._m(6),_c('div',{staticClass:"index_subhead"},[_vm._v("项目周期为2个月，分为三大阶段十个步骤展开")]),_vm._m(7)]),_c('div',{staticClass:"serve_m_box"},[_vm._m(8),_vm._m(9),_c('div',{staticClass:"serve_m_type"},_vm._l((_vm.hisTypeList),function(item,index){return _c('div',{key:index,class:['serve_m_type_item', _vm.isType == item.type ? 'serve_m_type_isItem' : ''],on:{"click":function($event){return _vm.changeTab(item.type)}}},[_vm._v(" "+_vm._s(item.value)+" ")])}),0),_c('div',{staticClass:"serve_m_hospital"},_vm._l((_vm.hisList2),function(item,index){return _c('div',{key:index,staticClass:"serve_m_hospital_item",on:{"click":function($event){return _vm.getDetails(item.hospitalId)}}},[_c('img',{staticClass:"serve_m_hospital_img",attrs:{"src":item.hospitalLogo}}),_c('div',{staticClass:"serve_m_hospital_name"},[_vm._v(_vm._s(item.hospitalName))])])}),0),_c('div',{staticClass:"index_more",on:{"click":_vm.stretch}},[_vm._v(_vm._s(_vm.hisShow ? '收起' : '查看更多'))])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_banner"},[_c('div',{staticClass:"m_banner_text"},[_vm._v("医院战略发展规划")]),_c('div',{staticClass:"m_banner_i"}),_c('div',{staticClass:"m_banner_text2"},[_vm._v("五定一做一分析，精准战略定位和发展方向")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_title"},[_c('span',{staticClass:"index_title_before"}),_c('span',{staticClass:"index_title_span"},[_vm._v("项目框架")]),_c('span',{staticClass:"index_title_after"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_title"},[_c('span',{staticClass:"index_title_before"}),_c('span',{staticClass:"index_title_span"},[_vm._v("实施效果")]),_c('span',{staticClass:"index_title_after"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"development_m_implement"},[_c('div',{staticClass:"development_m_implement_left"},[_c('div',{staticClass:"development_m_implement_name"},[_vm._v("发掘潜力")]),_c('div',{staticClass:"development_m_implement_info"},[_vm._v("发掘医院成长壮大的关键因素，确定战略地图，校正发展方向及行走步幅，实现医院总体目标。")])]),_c('img',{staticClass:"development_m_implement_img",attrs:{"src":require("../assets/img/development_m_img.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"development_m_list"},[_c('div',{staticClass:"development_m_item"},[_c('div',{staticClass:"development_m_top"},[_c('img',{staticClass:"development_m_icom",attrs:{"src":require("../assets/img/development_m_icom4.png"),"alt":""}}),_c('span',{staticClass:"development_m_name"},[_vm._v("谋篇布局")])]),_c('div',{staticClass:"development_m_title"},[_vm._v("为医院阶段性发展建立特色发展道路，夯实医疗管理制度。")])]),_c('div',{staticClass:"development_m_item"},[_c('div',{staticClass:"development_m_top"},[_c('img',{staticClass:"development_m_icom",attrs:{"src":require("../assets/img/development_m_icon3.png"),"alt":""}}),_c('span',{staticClass:"development_m_name"},[_vm._v("五定")])]),_c('div',{staticClass:"development_m_title"},[_vm._v("定业务、定财务、定专科、定人员、定物资。")])]),_c('div',{staticClass:"development_m_item"},[_c('div',{staticClass:"development_m_top"},[_c('img',{staticClass:"development_m_icom",attrs:{"src":require("../assets/img/development_m_icon2.png"),"alt":""}}),_c('span',{staticClass:"development_m_name"},[_vm._v("一做")])]),_c('div',{staticClass:"development_m_title"},[_vm._v("结合政策要求举措规划，助推医院高质量发展。")])]),_c('div',{staticClass:"development_m_item"},[_c('div',{staticClass:"development_m_top"},[_c('img',{staticClass:"development_m_icom",attrs:{"src":require("../assets/img/development_m_icon1.png"),"alt":""}}),_c('span',{staticClass:"development_m_name"},[_vm._v("一分析")])]),_c('div',{staticClass:"development_m_title"},[_vm._v("在数据分析基础上确定“五定一做”， 重点分析业务、财务、人员、专科、物资等。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_title"},[_c('span',{staticClass:"index_title_before"}),_c('span',{staticClass:"index_title_span"},[_vm._v("项目成果")]),_c('span',{staticClass:"index_title_after"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_title"},[_c('span',{staticClass:"index_title_before"}),_c('span',{staticClass:"index_title_span"},[_vm._v("项目完成周期")]),_c('span',{staticClass:"index_title_after"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"development_m_cycle"},[_c('div',{staticClass:"development_m_cycle_item"},[_c('div',{staticClass:"development_m_cycle_name"},[_vm._v("调研与资料收集(1-2周)")]),_c('div',{staticClass:"development_m_cycle_introduce"},[_c('p',[_vm._v("1.项目启动，观念先行;")]),_c('p',[_vm._v("2.调研与资料收集;")]),_c('p',[_vm._v("3.确定思路，结合国家、上级规划思路及要求，编制“十四五”规划纲要。")])])]),_c('img',{staticClass:"development_m_cycle_img",attrs:{"src":require("../assets/img/government_12.png"),"alt":""}}),_c('div',{staticClass:"development_m_cycle_item"},[_c('div',{staticClass:"development_m_cycle_name"},[_vm._v("报告撰写(3-6周)")]),_c('div',{staticClass:"development_m_cycle_introduce"},[_c('p',[_vm._v("4.医院战略环境分析;")]),_c('p',[_vm._v("5.确定战略目标，提出可实施的方案和保障措施;")]),_c('p',[_vm._v("6.座谈和研讨，取得共识;")]),_c('p',[_vm._v("7.完成并提交报告初稿。")])])]),_c('img',{staticClass:"development_m_cycle_img",attrs:{"src":require("../assets/img/government_17.png"),"alt":""}}),_c('div',{staticClass:"development_m_cycle_item"},[_c('div',{staticClass:"development_m_cycle_name"},[_vm._v("报告完善与提交(7-8周)")]),_c('div',{staticClass:"development_m_cycle_introduce"},[_c('p',[_vm._v("8.座谈研讨，综合意见。")]),_c('p',[_vm._v("9.完善项目报告。")]),_c('p',[_vm._v("10.组织中高层十四五规划报告培训解读。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_title"},[_c('span',{staticClass:"index_title_before"}),_c('span',{staticClass:"index_title_span"},[_vm._v("服务案例")]),_c('span',{staticClass:"index_title_after"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_subhead"},[_vm._v("易策医管集团已累计为超过"),_c('span',{staticStyle:{"color":"#2D43F2","font-weight":"500"}},[_vm._v("800+")]),_vm._v("家医院提供优质服务")])
}]

export { render, staticRenderFns }