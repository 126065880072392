<template>
  <div>
    <div class="pc_development">
      <div class="banner-box">
        <div class="banner-boxs">
          <div class="banner-name">医院战略发展规划</div>
          <div class="banner-little-name">
            五定一做一分析，精准战略定位和发展方向
          </div>
          <div class="banner-bnt" @click="openPDF">下载资料</div>
        </div>
      </div>
      <div class="review_bg">
        <!-- 项目框架 -->
        <div v-scroll-reveal>
          <div class="flex title2">
            <img class="titleIcon1" src="../assets/img/titleIcon1.png" />
            项目框架
            <img
              class="titleIcon1 rotate180"
              src="../assets/img/titleIcon1.png"
            />
          </div>
          <div class="flex mes2 ehpMes2">
            <img src="../assets/img/titleIcon2.png" />
          </div>
          <img class="d-img-b" src="../assets/img/developmentImg1.png" alt="" />
        </div>

        <!-- 实施效果 -->
        <div v-scroll-reveal>
          <div class="flex title2">
            <img class="titleIcon1" src="../assets/img/titleIcon1.png" />
            实施效果
            <img
              class="titleIcon1 rotate180"
              src="../assets/img/titleIcon1.png"
            />
          </div>
          <div class="flex mes2 ehpMes2">
            <img src="../assets/img/titleIcon2.png" />
          </div>
          <img class="d-img-b2" src="../assets/img/developmentImg2.png" />
          <div class="flex d-img-btn" @click="openFun(true)">了解详情</div>
        </div>

        <!-- 项目成果 -->
        <div class="achievementDiv" v-scroll-reveal>
          <div class="flex title2">
            <img class="titleIcon1" src="../assets/img/titleIcon1.png" />
            项目成果
            <img
              class="titleIcon1 rotate180"
              src="../assets/img/titleIcon1.png"
            />
          </div>
          <div class="flex mes2 ehpMes2">
            <img src="../assets/img/titleIcon2.png" />
          </div>
          <div class="flex flexSB achievement">
            <img
              v-for="(item, index) in developmentImgList"
              :key="index"
              :src="item.img"
            />
          </div>
          <div class="flex">《医院发展环境分析报告及医院战略发展规划报告》</div>
        </div>
      </div>
      <!-- 项目完成周期 -->
      <div class="government-cycle-bg" v-scroll-reveal>
        <div class="content-box d-time">
          <div style="padding-top: 79px">
            <div class="flex title2">
              <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
              <span>项目完成周期</span>
              <img
                class="titleIcon1 rotate180"
                src="../assets/img/titleIcon1.png"
                alt=""
              />
            </div>
            <div class="flex mes2">
              <img src="../assets/img/titleIcon2.png" alt="" />
            </div>
          </div>
          <div class="flex flexSB government-cycle">
            <div class="government-cycle-item">
              <div
                class="government-cycle-name"
                style="background-color: #c43694"
              >
                调研与资料收集(1-2周)
              </div>
              <div class="government-cycle-introduce">
                <p>1.项目启动，观念先行;</p>
                <p>2.调研与资料收集;</p>
                <p>
                  3.确定思路，结合国家、上
                  级规划思路及要求，编制“十四五”规划纲要。
                </p>
              </div>
            </div>
            <img
              class="government-cycle-img"
              src="../assets/img/government_12.png"
              alt=""
            />
            <div class="government-cycle-item">
              <div
                class="government-cycle-name"
                style="background-color: #684ab7"
              >
                报告撰写(3-6周)
              </div>
              <div class="government-cycle-introduce">
                <p>4.医院战略环境分析;</p>
                <p>5.确定战略目标，提出可实施的方案和保障措施;</p>
                <p>6.座谈和研讨，取得共识;</p>
                <p>7.完成并提交报告初稿。</p>
              </div>
            </div>
            <img
              class="government-cycle-img"
              src="../assets/img/government_17.png"
              alt=""
            />
            <div class="government-cycle-item">
              <div
                class="government-cycle-name"
                style="background-color: #2c4fdb"
              >
                报告完善与提交(7-8周)
              </div>
              <div class="government-cycle-introduce">
                <p>8.座谈研讨，综合意见。</p>
                <p>9.完善项目报告。</p>
                <p>10.组织中高层十四五规划报告培训解读。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 服务累计 -->
      <serviceAcc :bg="1" />
      <!-- 项目专家 -->
      <expertItemMod />
      <relation :open="open" @openFun="openFun"/>
    </div>
    <mobileDevelopment
      :developmentImgList="developmentImgList"
      class="mobile_development"
    />
  </div>
  
</template>

<script>
import serviceAcc from "../components/serviceAcc";
import expertItemMod from "../components/expertItemMod";
import relation from "../components/relation";
import mobileDevelopment from '@/mobileViews/development.vue'
export default {
  name: "development",
  components: { serviceAcc, expertItemMod,relation, mobileDevelopment },
  data() {
    return {
      open:false,
      developmentImgList: [
        { img: require("../assets/img/developmentImg3.png") },
        { img: require("../assets/img/developmentImg4.png") },
        { img: require("../assets/img/developmentImg5.png") },
        { img: require("../assets/img/developmentImg6.png") },
      ],
    };
  },
  methods: {
    openPDF() {
      window.open(
        "http://yice-prod.oss-cn-guangzhou.aliyuncs.com/other/protalDown/%E6%98%93%E7%AD%96%E5%8C%BB%E7%AE%A1-%E5%8C%BB%E9%99%A2%E6%88%98%E7%95%A5%E5%8F%91%E5%B1%95%E8%A7%84%E5%88%92.pdf"
      );
    },
    openFun(bo){
      this.open = bo
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-box {
  width: 100%;
  height: 400px;
  background-image: url("../assets/img/development.png");
  background-repeat: no-repeat;
  // background-position: 0 -181px;
  background-size: 100%;
  .banner-boxs {
    width: 1200px;
    margin: 0 auto;

    .banner-name {
      font-size: 52px;
      font-weight: 400;
      color: #fff;
      padding-top: 92px;
    }

    .banner-little-name {
      margin: 30px 0 50px 0;
      color: white;
    }

    .banner-bnt {
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #2d43f2;
      border-radius: 50px;
      color: #fff;
      font-size: 16px;
      margin-top: 48px;
      cursor: pointer;
    }
  }
}
.content-box {
  width: 1200px;
}
.review_bg {
  margin-top: 60px;
  background-image: url("../assets/img/review_bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  .d-img-b {
    display: block;
    width: 1200px;
    height: 419px;
    margin: 0 auto;
    margin-bottom: 75px;
  }
  .d-img-b2 {
    display: block;
    width: 1200px;
    height: 503px;
    margin: 0 auto;
    margin-bottom: 75px;
  }
  .d-img-btn {
    width: 185px;
    height: 50px;
    margin: 0 auto;
    margin-bottom: 90px;
    background: #3370ff;
    border-radius: 25px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
  }
  .achievementDiv {
    padding-bottom: 130px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-image: url("../assets/img/core_advantage_bg2.png");
    .achievement {
      width: 1200px;
      margin: 0 auto;
      margin-bottom: 50px;
      img {
        width: 260px;
        height: 368px;
        box-shadow: 0px 3px 6px 1px rgba(185, 185, 185, 0.6);
      }
    }
  }
}
.government-cycle-bg {
  background-image: url("../assets/img/cycle_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 107px;
  .government-cycle {
    display: flex;
    align-items: center;
    .government-cycle-item {
      width: 370px;
      height: 326px;
      background-color: #fff;
      .government-cycle-name {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        padding: 13px;
        text-align: center;
      }
      .government-cycle-introduce {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        p {
          padding: 0 30px 0 51px;
          margin: 15px 0;
          position: relative;
          &::before {
            content: "";
            width: 14px;
            height: 14px;
            position: absolute;
            top: 5px;
            left: 25px;
            background-image: url("../assets/img/review_13.png");
            background-repeat: no-repeat;
            background-size: 100%;
          }
        }
      }
    }
    .government-cycle-img {
      width: 11px;
      height: 25px;
    }
  }
}
.d-time {
  margin: 0 auto;
}

.mobile_development {
  display: none;
}
@media screen and (max-width: 650px) {
  .mobile_development {
    display: block;
    background-color: #fff;
  }
  .pc_development {
    display: none;
  }
}
</style>
